:root 
{
    --root-font-size: 18px;

    --yellow: #f8ef02;
    --cyan: #00ffd2;
    --red: #ff003c;
    --blue: #136377;
    --green: #446d44;
    --purple: purple;
    --black: #000;
    --white: #fff;
    --dark: #333;

    --fg: var(--black);
    --bg: transparent;
    --ac: var(--black);

    --yellow-cyberpunk-font: #feef00;
    --cyan-cyberpunk-font1:#46dffb;
    --cyan-cyberpunk-font2:#45aefb;

    --banner-color1: var(--purple); 
    --banner-color2: var(--red);
    --banner-color3: var(--yellow); 
    --banner-color4: var(--cyan); 
    --banner-gradient-stop: 405px;

    --input-padding-top: 0px;
    --input-padding-bot: 0.2rem;
    --input-padding-left: 1rem;
    --input-padding-right: 2rem;
    --input-font-size: 1.2rem;
    --input-width: 240px;

    --checkbox-size: 28px;
    --checkbox-inner-size: 16px;

    --radio-size: 28px;
    --radio-inner-size: 16px;
}


html{
  overflow-y: auto;
}

.trajin-pro {
  font-family: "TrajanPro";
}
.monkirta {
  font-family: "monkirta";
}
.netrunner {
  font-family: "netrunner";
}

.app {
  /* background-color: var(--background-color); */
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  font-size: calc(1.3em); 
  color: var(--text-color);

  /* background-color: #282c34; */
  

  
  height: 100vh;
  width: 100vw;
  /* background-size: cover;
  background-position: center; */
  display: flex;
  /* justify-content: center;
  align-items: center; */
}



/* .mainContent{
  padding:20px;
} */

/* .navbar {
  width: 100%;
  height: 6em;
  border-bottom: 2px solid white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
} */

/* .menu {
  height: 3em;
  width: 4em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 2em;
  cursor: pointer;
}

.menu div {
  width: 90%;
  height: 0.25em;
  background-color: white;
  border-radius: 1em;
} */

/* .menu div:nth-of-type(2) {width: 40%;}  */

.select {
  -webkit-touch-callout: unset; /* iOS Safari */
    -webkit-user-select: unset; /* Safari */
     -khtml-user-select: unset; /* Konqueror HTML */
       -moz-user-select: unset; /* Old versions of Firefox */
        -ms-user-select: unset; /* Internet Explorer/Edge */
            user-select: unset; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}