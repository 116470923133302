@import "rsuite/dist/rsuite.min.css";

@font-face {
    font-family: "TrajanPro";
    src: local("TrajanPro"),
        url("./assets/fonts/trajan-pro/Trajan Pro.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "monkirta";
    src: local("monkirta"),
        url("./assets/fonts/monkirta/monkirta.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
  font-family: "netrunner";
  src: local("netrunner"),
  url("./assets/fonts/netrunner/netrunner.ttf") format("truetype");
  
}


@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@500;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&amp;display=swap");


* {
    box-sizing: border-box;
    position: relative;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* :root {
  --background-color: transparent;
  --medium-background-color: #fafafa;
  --light-background-color: white;
  --bar-color: rgba(0, 147, 211, 0.5);
  --bar-color-old: linear-gradient(to right, rgba(0, 37, 84, 0.5), rgba(0, 55, 124, 0.5) 25%, rgba(0, 147, 211, 0.5) 70%);

  --text-color: #282C34;
  --accent: #9CA3AF;
  --disabled: #b5bdc9;

  --error: #dc3545;
  --warning: #ffc107;
  --success: #02d14b;
  --info: #17a2b8;
  --border: #e0e0e0;
  --light-blue: #e0f7fa;
  --green: #02d14b;
  --toastify-color-success: #02d14b;
  --toastify-color-error: #dc3545;
  --toastify-color-info: #387EF5;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

#dark-mode {
  --background-color: #282C34;
  --text-color: #FFFFFF;
  --accent: #9CA3AF;
  --c3-blue: #387EF5;
} */


/* 




*,
::before,
::after {

  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: var(--accent);
}

.bg {
  background-color: var(--background-color);
}

.btn-light:hover {
  background-color: var(--medium-background-color);
}

.btn-light {
  background-color: var(--light-background-color);
}

.bg-clear {
  background-color: transparent;
}

.menu-padding {
  margin-left: 0 !important;
}

a:-webkit-any-link {
  text-decoration: none !important;
}

.bell-button {
  color: white;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.absolute {
  position: absolute;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0px;
}

.inline-flex {
  display: inline-flex;
}

.justify-center {
  justify-content: center;
}

.p-2 {
  padding: 0.5rem !important;
}

.rounded-md {
  border-radius: 0.375rem;
}

.bg-clear {
  background-color: transparent;
}

.menu-padding {
  margin-left: 0 !important;
}

.h-6 {
  height: 1.5rem;
}

.w-6 {
  width: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.w-8 {
  width: 2rem;
}

.h-12 {
  height: 3rem;
}

.w-auto {
  width: auto;
}

.rounded-full {
  border-radius: 9999px;
}

.ml-3 {
  margin-left: 0.75rem;
}

.block {
  display: block;
}


.mx-auto {
  padding: 0 !important;
  margin-left: 0;
  margin-right: 20px;
}

.max-w-7xl {
  max-width: 98%;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--text-color) !important;
}


.app>div>div>.rc-scrollbars-container {
  margin-top: 50px;
}

html {
  overflow: hidden !important;
}

.splash {
  background-color: var(--c3-gradient) !important;
  height: 100%;
  width: 100%;
  position: fixed;
}

.c3-toolbar {
  background-color: var(--medium-background-color) !important;
  height: 50px;
  border-radius: 10px 10px 0 0;
}

.rs-btn-toolbar.rs-stack {
  padding: 7px !important;
}

.c3-button {
  color: white !important;
}

.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active {
  color: white !important;
}

.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
  color: white !important;
}

.selected-row,
.selected-row .rs-table-cell {
  background-color: var(--c3-blue) !important;
  color: white !important;
  font-weight: bold;
}

.rs-table-cell-content>.rs-checkbox {
  margin-top: -9px;
}

.rs-btn-disabled {
  color: var(--accent) !important;
}

.rs-btn-disabled:hover {
  color: var(--accent) !important;
}

.rs-table-cell {
  background-color: transparent;
  border-color: var(--border);
}

.rs-stack-item:has(.editor) {
  width: 100%;
}

.rs-stack-item:has(.check-editor) {
  width: 100%;
}

.rs-stack-item.editor {
  justify-content: flex-end;
  display: flex;
  margin-right: 3px;
}

.rs-stack-item.check-editor {
  justify-content: flex-end;
  display: flex;
}

.pagination {
  padding: 10px;
  border-bottom: 1px solid var(--border) !important;
  border-left: 1px solid var(--border) !important;
  border-right: 1px solid var(--border) !important;
}

.toastContainer {
  display: flexbox;
  flex-direction: column;
}

.toastButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.contextMenu {
  z-index: 9999;
}

.invalid {
  border-color: var(--error) !important;
}

.error-message {
  color: var(--error);
}

.error-hide {
  display: none;
}

.rs-input-group:not(.rs-input-group-inside)> :first-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child>.rs-input {
  background-color: transparent;
}

span.rs-input-group-addon {
  background-color: transparent;
}

.custom-scroll-list-box>*>* {
  overflow: hidden !important;
}

.modal-header {
  background: var(--bar-color);
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  border-radius: 6px 6px 0 0;
  padding-left: 20px;
  color: var(--text-color) !important
}

.modal-header .rs-btn-close {
  color: var(--text-color);
}

.modal-header .rs-btn-close:hover {
  color: var(--text-color);
}

.modal-header>* {
  color: var(--text-color);
}

.modal-footer {
  background: var(--bar-color);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-radius: 0 0 6px 6px;
  padding-right: 20px;
}

.modal-footer>* {
  color: var(--text-color) !important;
}

.button-toolbar {
  background: var(--bar-color) !important;
  padding: '10px 0 10px 20px';
  width: '100%';
  border-radius: 6px 6px 0 0;
  display: 'flex';
}


.rs-btn-disabled {
  color: var(--disabled) !important;
}

.pagination {
  background: var(--bar-color) !important;
  border-radius: 0 0 6px 6px;
  border: none !important;
  color: var(--text-color) !important;
  font-weight: 500;
}

.pagination>.rs-btn {
  color: var(--text-color) !important;
}

.rs-pagination .rs-pagination-xs>button {
  color: var(--text-color) !important;
}

.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  color: var(--disabled) !important;
}

button.rs-pagination-btn {
  color: var(--text-color) !important;
}

body>hmr-error-overlay {
  display: none;
}

.Toastify__toast--undefined{
  background-color: var(--background-color);
  color: var(--text-color)
} */