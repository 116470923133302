.rs-sidenav-toggle{
    margin-top: 0;
    align-items: flex-end;
}

.navigation-bar>*{
    height: 100%;
    max-width: 250px;
    z-Index: 3;
    position: absolute;
    flex-direction: column;
    justify-content: space-between; 
}

.rs-sidenav-body{
    height: 100%;
    justify-content: space-between;
}

.rs-sidenav{
    background-color: var(--banner-color4) !important
}

.rs-sidenav>*>*{
    background: transparent !important;
}

.rs-sidenav-item{
    background: transparent !important;
}

.rs-sidenav-toggle{
    background-color: transparent !important
}


.versionInfo{
    color:white
}

.rs-sidenav-item-active{
    background-color: var(--c3-blue) !important;
    color: black !important;
    font-weight: bold;

}

.rs-dropdown-menu:not(.rs-dropdown-menu-collapse-in){
    margin-left: 27px;
    background: var(--c3-blue);
    color:black;
    z-index: 9999 !important;
}
.rs-dropdown.rs-dropdown-placement-bottom-start, .rs-dropdown.rs-dropdown-placement-bottom-start>a#menubutton-\:rl\:{
    height: 100%;
}

.rs-nav-vertical>*>.rs-dropdown-menu:not(.rs-dropdown-menu-collapse-in){
    margin-left: 85px;
}

.rs-dropdown-menu:not(.rs-dropdown-menu-collapse-in) .rs-dropdown-item{
    color:black;
}

.rs-sidenav>*{
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
}

.rs-dropdown-menu{
    margin-left: 0;
}

.rs-dropdown-header{
    color:black;
}

.rs-sidenav-nav.rs-nav.rs-nav-default.rs-nav-vertical:last-of-type(.rs-dropdown-submenu){
    background: red !important;
}

.c3-navbar-brand {
    background-color: black !important;
    clip-path: polygon(0 0, 2% 0, 100% 100%, 0 100%);
    content: "";
    display: block;
    height: 65px;
    top: 0;
    width: 25px;
    padding-left: 30px !important;
}

.rs-sidenav-header{
    height: 80px;
}
.rs-sidenav-inverse .rs-sidenav-toggle{
    border-top-color: transparent;
}

.rs-sidenav-collapse-in {
width: 215px !important;
z-index: 9999 !important;
}

.expanded-buttons{
    padding:5px;
}

.expander>*{
    color:black !important
}


.cyber-banner
{
    height: 80px;

    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    line-height: 50px;
    
    /*default: --purple --red --yellow --cyan*/
    background: linear-gradient(90deg, var(--banner-color1), var(--banner-color2), var(--banner-color3), var(--banner-color4) 100%);
    background-repeat: no-repeat;
    background-size: 100% 64px;
}

.cyber-banner.bg-yellow,
.cyber-banner-short.bg-yellow
{
    --banner-color1: var(--cyan);
    --banner-color2: var(--purple);
    --banner-color3: var(--red);
    --banner-color4: var(--yellow);
}

.cyber-banner.bg-purple,
.cyber-banner-short.bg-purple
{
    --banner-color1: var(--red);
    --banner-color2: var(--yellow);
    --banner-color3: var(--cyan);
    --banner-color4: var(--purple);
}

.cyber-banner.bg-red,
.cyber-banner-short.bg-red
{
    --banner-color1: var(--yellow);
    --banner-color2: var(--cyan);
    --banner-color3: var(--purple);
    --banner-color4: var(--red);
}


.cyber-banner::before,
.cyber-banner-short::before
{
    content: " ";
    height: inherit;
    display: block;
    float: left;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
}

/*
Note: Inline svg shit doesnt work sometimes. Make sure to optimize it with https://jakearchibald.github.io/svgomg/ (import and download)
*/
.cyber-banner::before
{
    width: 100%;
    height: 80px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="405" height="50"><path d="M0 0v50h20L45 0zM47 0 22 50h28L75 0zM79 0 54 50h26l25-50zM111 0 86 50h24l25-50zM143 0l-25 50h22l25-50zM175 0l-25 50h20l25-50zM207 0l-25 50h18l25-50zM239 0l-25 50h16l25-50zM271 0l-25 50h14l25-50zM303 0l-25 50h12l25-50zM335 0l-25 50h10l25-50zM367 0l-25 50h8l25-50zM399 0l-25 50h6l25-50z"/></svg>');;
    transform: rotate(-90deg);
    transform: scaleX(100%);

}