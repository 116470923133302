


.cyber-banner,
.cyber-banner-short
{
    width: 100%;
    height: 50px;
    background-repeat: no-repeat;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    line-height: 50px;
    
    /*default: --purple --red --yellow --cyan*/
    background: linear-gradient(90deg, var(--banner-color1), var(--banner-color2), var(--banner-color3), var(--banner-color4) var(--banner-gradient-stop));
}

.cyber-banner-short
{
    --banner-gradient-stop: 220px;
}

.cyber-banner.bg-yellow,
.cyber-banner-short.bg-yellow
{
    --banner-color1: var(--cyan);
    --banner-color2: var(--purple);
    --banner-color3: var(--red);
    --banner-color4: var(--yellow);
}

.cyber-banner.bg-purple,
.cyber-banner-short.bg-purple
{
    --banner-color1: var(--red);
    --banner-color2: var(--yellow);
    --banner-color3: var(--cyan);
    --banner-color4: var(--purple);
}

.cyber-banner.bg-red,
.cyber-banner-short.bg-red
{
    --banner-color1: var(--yellow);
    --banner-color2: var(--cyan);
    --banner-color3: var(--purple);
    --banner-color4: var(--red);
}


.cyber-banner::before,
.cyber-banner-short::before
{
    content: " ";
    height: inherit;
    display: block;
    float: left;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
}

/*
Note: Inline svg shit doesnt work sometimes. Make sure to optimize it with https://jakearchibald.github.io/svgomg/ (import and download)
*/
.cyber-banner::before
{
    width: 405px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="405" height="50"><path d="M0 0v50h20L45 0zM47 0 22 50h28L75 0zM79 0 54 50h26l25-50zM111 0 86 50h24l25-50zM143 0l-25 50h22l25-50zM175 0l-25 50h20l25-50zM207 0l-25 50h18l25-50zM239 0l-25 50h16l25-50zM271 0l-25 50h14l25-50zM303 0l-25 50h12l25-50zM335 0l-25 50h10l25-50zM367 0l-25 50h8l25-50zM399 0l-25 50h6l25-50z"/></svg>');
}

.cyber-banner-short::before
{
    width: 220px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="220" height="50"><path d="m0 0-10 50h20L35 0zM37 0 12 50h18L55 0zM59 0 34 50h16L75 0zM81 0 56 50h14L95 0zM103 0 78 50h12l25-50zM125 0l-25 50h10l25-50zM147 0l-25 50h8l25-50zM169 0l-25 50h6l25-50zM191 0l-25 50h4l25-50zM213 0l-25 50h2l25-50z"/></svg>')
}

ul.settings.rs-dropdown-menu{
    position: absolute;
    z-index: 99999 !important;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-radius: 2px;
    margin-top: -10px;
    margin-left: -80px;
    width: 160px;
}

ul.settings.rs-dropdown-menu>*{
    color: white;
    margin-top: 4px !important;
    cursor: pointer;
    display: block;
}

ul.settings.rs-dropdown-menu>*:hover{
    --primary: hsl(var(--primary-hue), 85%, calc(var(--primary-lightness, 50) * 0.8%));
    display: block;
}
  
.navbar-brand{
    color: 'black' !important;
    display:'flex' !important;
    justify-content:'center' !important;
    align-items:'center' !important;
    font-size: 1.5rem !important;
    float: left !important;
    margin-left: 20px;
    color: red;
}