.loginForm {
    background-color: rgb(255, 255, 255, 0.33);
    box-shadow: 0px 8px 32px 0 rgb(0, 0 , 255, 0.33);
    backdrop-filter: blur(3px);
    padding: 0px 16px 16px 16px;
    margin: 1.0em;
    border-radius: 16px;
}

.loginForm .rs-form-control-label {
    color: black;
    font-weight: bolder;
    text-align: left!important;
    width: 70px!important;
}

.loginForm .rs-form-control-wrapper {
    justify-content: flex-end;
    width: 70%!important;
}

.loginFormGroup .rs-form-group {
    
    display: flex;
    justify-content: space-between;
    padding-right:20px;
    width: 100%;
}

.loginFormLabel {
    font-size: 1.0em!important;
    padding-right: 10px!important;
    margin-right: 50px!important;
}

.loginLogo  {
    display: flex;
    align-content: center;
    justify-content: center;
}

.loginForm .loginButton {
    display: 'flex'!important;
    justify-content: 'right'!important;
}

.loginLogo img {
    width:75%;
    margin-top: 10px;
    margin-left: -50px;
    margin-right: -50px;
    margin-bottom: 10px;
    object-fit:cover;
  
}